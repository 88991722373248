/** @jsx jsx */
import React, { useMemo } from 'react';
import { css, jsx, styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import LoadingButton from '@atlaskit/button/loading-button';
import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import EmojiKeyboardIcon from '@atlaskit/icon/core/migration/text-style--emoji-keyboard';
import TrashIcon from '@atlaskit/icon/core/migration/delete--trash';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { ConditionalWrapper } from '@atlassian/jira-business-conditional-wrapper/src/index.tsx';
import { useIsWorkflowOperationInProgress } from '@atlassian/jira-business-workflows/src/controllers/workflow-actions/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { BoardHeaderDropdown_view$key } from '@atlassian/jira-relay/src/__generated__/BoardHeaderDropdown_view.graphql';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { ModalEntryPointContainer } from '@atlassian/jira-entry-point-modal-container/src/index.tsx';
import type { BoardHeaderDropdown_status$key } from '@atlassian/jira-relay/src/__generated__/BoardHeaderDropdown_status.graphql';
import type { BoardHeaderDropdown_project$key } from '@atlassian/jira-relay/src/__generated__/BoardHeaderDropdown_project.graphql';
import { ContactAdminToModifyBoardMessage } from '../../../../../common/ui/contact-admin-to-modify-board/index.tsx';
import { OpenProjectSettingsMultipleWorkflows } from '../../../../../common/ui/open-project-settings-multiple-workflows/OpenProjectSettingsMultipleWorkflows.tsx';
import { columnDeleteModalEntryPoint } from './column-delete-modal/entrypoint.tsx';
import messages from './messages.tsx';

export type Props = {
	hasMultipleWorkflows: boolean | undefined;
	isLoading: boolean | undefined;
	onRenameClick: () => void;
	columnName?: string;
	onOpenChange?: (newState: boolean) => void;
	projectFragment: BoardHeaderDropdown_project$key;
	viewFragment: BoardHeaderDropdown_view$key;
	statusFragment: BoardHeaderDropdown_status$key;
	refresh: () => void;
};

const BoardHeaderDropdownContent = ({
	projectFragment,
	viewFragment,
	statusFragment,
	hasMultipleWorkflows,
	isLoading,
	onRenameClick,
	columnName,
	onOpenChange,
	refresh,
}: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isWorkflowOperationInProgress = useIsWorkflowOperationInProgress();

	const project = useFragment(
		graphql`
			fragment BoardHeaderDropdown_project on JiraProject {
				canAdministerProject: action(type: EDIT_PROJECT_CONFIG) @required(action: THROW) {
					canPerform @required(action: THROW)
				}
				...OpenProjectSettingsMultipleWorkflows_project
			}
		`,
		projectFragment,
	);

	const canAdministerProject = project.canAdministerProject.canPerform;
	const actionsDisabled =
		!canAdministerProject || hasMultipleWorkflows === true || isWorkflowOperationInProgress;

	const view = useFragment(
		graphql`
			fragment BoardHeaderDropdown_view on JiraBoardView {
				id @required(action: THROW)
				columns(first: null) @required(action: THROW) {
					totalCount @required(action: THROW)
				}
			}
		`,
		viewFragment,
	);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		columnDeleteModalEntryPoint,
		useMemo(
			() => ({
				viewId: view.id,
			}),
			[view.id],
		),
	);
	const buttonTrigger = useEntryPointButtonTrigger(entryPointActions);

	const status = useFragment(
		graphql`
			fragment BoardHeaderDropdown_status on JiraStatus {
				statusId @required(action: THROW)
			}
		`,
		statusFragment,
	);

	const onlyOneColumnLeft = view.columns.totalCount <= 1;

	const runtimeProps = useMemo(
		() => ({
			columnId: status.statusId,
			refresh,
		}),
		[refresh, status.statusId],
	);

	return (
		<>
			<DropdownMenu
				testId="work-management-board.ui.board.column.column-header.header-dropdown.header-dropdown-menu"
				trigger={({ triggerRef, ...props }) =>
					isVisualRefreshEnabled() ? (
						<IconButton
							{...props}
							label=""
							isLoading={isLoading}
							appearance="subtle"
							icon={(iconProps) => (
								<ShowMoreHorizontalIcon
									{...iconProps}
									label={formatMessage(messages.columnActionsWithColumn, { columnName })}
									color={token('color.icon')}
								/>
							)}
							ref={triggerRef}
						/>
					) : (
						<StyledButton
							{...props}
							isLoading={isLoading}
							appearance="subtle"
							iconBefore={
								<ShowMoreHorizontalIcon
									label={formatMessage(messages.columnActionsWithColumn, { columnName })}
									color={token('color.icon')}
								/>
							}
							ref={triggerRef}
						/>
					)
				}
				onOpenChange={({ isOpen }) => {
					fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'jwmBoardColumnMoreButton', {
						columnMoreActionsDisabled: actionsDisabled,
					});
					if (onOpenChange && isVisualRefreshEnabled() && fg('visual-refresh_drop_5')) {
						onOpenChange(isOpen);
					}
				}}
				placement="bottom-end"
			>
				<DropdownItemGroup title={formatMessage(messages.dropdownHeaderTitle)}>
					<DropdownItem
						elemBefore={
							isVisualRefreshEnabled() ? null : <EmojiKeyboardIcon LEGACY_size="small" label="" />
						}
						onClick={() => {
							onRenameClick();
							fireUIAnalytics(
								createAnalyticsEvent({}),
								'dropdownItem clicked',
								'jwmBoardColumnRenameDropdownItem',
							);
						}}
						isDisabled={actionsDisabled}
					>
						{formatMessage(messages.rename)}
					</DropdownItem>
					<ConditionalWrapper
						condition={onlyOneColumnLeft}
						renderWrapper={(children) => (
							<Tooltip content={formatMessage(messages.deleteDisabledTooltip)}>{children}</Tooltip>
						)}
					>
						<DropdownItem
							isDisabled={onlyOneColumnLeft || actionsDisabled}
							elemBefore={
								isVisualRefreshEnabled() ? null : <TrashIcon LEGACY_size="small" label="" />
							}
							onClick={() => {
								fireUIAnalytics(
									createAnalyticsEvent({}),
									'dropdownItem clicked',
									'jwmBoardColumnDeleteDropdownItem',
								);
							}}
							ref={buttonTrigger}
						>
							{formatMessage(messages.delete)}
						</DropdownItem>
					</ConditionalWrapper>
					{hasMultipleWorkflows === true && (
						<div css={dropdownMessageWrapperStyles}>
							<OpenProjectSettingsMultipleWorkflows projectFragment={project} />
						</div>
					)}
					{!canAdministerProject && (
						<div css={dropdownMessageWrapperStyles}>
							<ContactAdminToModifyBoardMessage />
						</div>
					)}
				</DropdownItemGroup>
			</DropdownMenu>
			<ModalEntryPointContainer
				entryPointActions={entryPointActions}
				entryPointReferenceSubject={entryPointReferenceSubject}
				id="column-delete-modal"
				packageName="jiraWorkManagementBoard"
				runtimeProps={runtimeProps}
			/>
		</>
	);
};

const BoardHeaderDropdown = (props: Props) => (
	<ErrorBoundary id="jira-business.board.header-dropdown" packageName="jiraWorkManagementBoard">
		<BoardHeaderDropdownContent {...props} />
	</ErrorBoundary>
);

export default BoardHeaderDropdown;

const dropdownMessageWrapperStyles = css({
	paddingTop: token('space.100'),
	paddingRight: token('space.150'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.150'),
	maxWidth: '200px',
	marginBottom: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledButton = styled(LoadingButton)({
	flexShrink: 0,
});
